import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="wave-container">
        <div className="content">
          <h1 className="company-name">Faith Holdings</h1>
          <a href="mailto:wesley@faith.holdings" className="email-link">wesley@faith.holdings</a>
        </div>
        <div className="wave"></div>
      </div>
    </div>
  );
}

export default App;
